import React, { useState, useEffect } from "react";
import { useQuery } from "@apollo/react-hooks";
import { useCookies } from "react-cookie";
import bcrypt from "bcryptjs";
import { gql } from "apollo-boost";
import * as S from "./styles";

const GET_AUTH = gql`
  query AuthQuery {
    passwords {
      data {
        password
      }
    }
  }
`;

export default function Authentication({ children }) {
  const [value, setValue] = useState("");
  const [authResponse, setAuthResponse] = useState("");
  const [isAuthenticated, setAuthenticated] = useState(false);
  const [cookies, setCookie, removeCookie] = useCookies(["auth"]);
  const { loading, error, data } = useQuery(GET_AUTH, {
    fetchPolicy: "no-cache"
  });

  const checkAuthCookie = () => {
    let passCheck = false;
    data.passwords.data.forEach(hash => {
      if (cookies.auth === hash.password) {
        passCheck = true;
        setCookie("auth", hash.password, { maxAge: 60 * 60 * 24 * 30 });
      } else {
        removeCookie("auth");
      }
    });
    setAuthenticated(passCheck);
  };

  useEffect(() => {
    if (data && "auth" in cookies) {
      checkAuthCookie();
    }
  });

  if (error) return "Couldn't authenticate :(";
  if (loading) return "";

  const handleChange = event => {
    setAuthResponse("");
    setValue(event.target.value);
  };

  const checkPassword = () => {
    if (data) {
      const hashes = data.passwords.data;
      hashes.forEach(hash => {
        bcrypt.compare(value, hash.password, (error, result) => {
          if (result) {
            setAuthenticated(true);
            setCookie("auth", hash.password, { maxAge: 60 * 60 * 24 * 30 });
          }
        });
      });
    }
    setValue("");
    setAuthResponse("NOPE");
  };

  const handleLogOut = () => {
    removeCookie("auth");
    setAuthenticated(false);
  };

  const handleSubmit = event => {
    event.preventDefault();
    checkPassword();
  };

  return (
    <>
      {isAuthenticated && (
        <>
          <S.LogOut onClick={handleLogOut}>Log Out</S.LogOut>
          {children}
        </>
      )}
      {!isAuthenticated && (
        <S.AuthForm onSubmit={handleSubmit}>
          <S.Input
            disabled={loading}
            onChange={handleChange}
            type="password"
            value={value}
          />
          {authResponse && <S.AuthResponse>{authResponse}</S.AuthResponse>}
        </S.AuthForm>
      )}
    </>
  );
}
