import React from "react";
import { Link } from "react-router-dom";
import logo from "../mars_logo.png";
import * as S from "./styles";

export default function Header() {
  return (
    <S.Header>
      <S.InnerHeader>
        <Link to="/">
          <S.Logo src={logo} width={60} />
        </Link>
      </S.InnerHeader>
    </S.Header>
  );
}
