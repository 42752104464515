import React from "react";
import { ApolloProvider } from "@apollo/react-hooks";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { CookiesProvider } from "react-cookie";
import client from "../ApolloClient";
import FetchPosts from "../FetchPosts";
import Post from "../Post";
import Header from "../Header";
import Authentication from "./Authentication";
import * as S from "./styles";

export default function App() {
  return (
    <S.App>
      <Router>
        <Header />
        <ApolloProvider client={client}>
          <CookiesProvider>
            <Authentication>
              <S.Content>
                <Switch>
                  <Route exact={true} path="/tag/:tag">
                    <FetchPosts>
                      {({ posts, selectedTag }) =>
                        posts.map(post => (
                          <Post
                            key={`post-${post.id}`}
                            post={post}
                            selectedTag={selectedTag}
                          />
                        ))
                      }
                    </FetchPosts>
                  </Route>
                  <Route exact={true} path="/post/:postId">
                    <FetchPosts>
                      {({ posts, selectedTag }) =>
                        posts.map(post => (
                          <Post
                            key={`post-${post.id}`}
                            post={post}
                            selectedTag={selectedTag}
                          />
                        ))
                      }
                    </FetchPosts>
                  </Route>
                  <Route path="/">
                    <FetchPosts>
                      {({ posts }) =>
                        posts.map(post => (
                          <Post key={`post-${post.id}`} post={post} />
                        ))
                      }
                    </FetchPosts>
                  </Route>
                </Switch>
              </S.Content>
            </Authentication>
          </CookiesProvider>
        </ApolloProvider>
      </Router>
    </S.App>
  );
}
