import styled from "styled-components";

export const App = styled.div`
  padding: 0 40px;
`;

export const Content = styled.div`
  margin: 0 auto;
  max-width: 840px;
  position: relative;
  width: 100%;
`;

