import React from "react";
import { formatDistanceStrict, format } from "date-fns";
import { Link, useParams } from "react-router-dom";
import * as S from "./styles";

export default function Post({ post, selectedTag }) {
  const { postId } = useParams();
  return (
    <S.Post>
      {postId && <S.Title>{post.title}</S.Title>}
      {!postId && (
        <S.Title>
          <S.TitleLink to={`/post/${post.id}`}>{post.title}</S.TitleLink>
        </S.Title>
      )}
      <S.Details>
        <b>{post.owner.first_name}</b> -{" "}
        {format(
          new Date(`${post.created_on} UTC`),
          "LLL do, y 'at' h:mm aaaaa'm"
        )}
      </S.Details>
      <S.Details>
        Logan is{" "}
        {formatDistanceStrict(
          new Date(`${post.created_on} UTC`),
          new Date("2019-11-13 14:49:00 UTC")
        )}{" "}
        old
      </S.Details>
      <S.Tags>
        {post.tags
          .split(",")
          .filter(tag => tag.length)
          .map(tag => (
            <Link key={`post-${post.id}-${tag}`} to={`/tag/${tag}`}>
              <S.Tag highlight={selectedTag === tag}>{tag}</S.Tag>
            </Link>
          ))}
      </S.Tags>
      <S.Content dangerouslySetInnerHTML={{ __html: post.content }} />
    </S.Post>
  );
}
