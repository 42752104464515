import { useQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import { useParams } from "react-router-dom";

const POSTS_QUERY = gql`
  query PostsQuery($tag: String $postId: Int) {
    posts(id: $postId, filter: { status_eq: "published", tags_contains: $tag }) {
      data {
        id
        status
        sort
        owner {
          id
          status
          first_name
          last_name
        }
        created_on
        modified_by {
          id
          status
          first_name
          last_name
        }
        modified_on
        content
        tags
        password_protected
        password
        title
        slug
      }
    }
  }
`;

export default function FetchPosts({ children }) {
  const { tag, postId } = useParams();
  const { loading, error, data } = useQuery(POSTS_QUERY, {
    variables: { postId, tag }
  });

  if (loading) return "Loading...";
  if (error) return "Error :(";

  return children({ posts: data.posts.data, selectedTag: tag });
}
