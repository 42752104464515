import styled from "styled-components";
import { Link } from "react-router-dom";

export const Content = styled.div`
  font-size: 18px;
  line-height: 26px;

  img {
    display: block;
    height: auto;
    margin: 0 auto;
    max-width: 80%;

    @media screen and (max-width: 640px) {
      max-width: 100%;
    }
  }
`;

export const Details = styled.div`
  font-size: 16px;
  font-style: italic;
  line-height: 24px;
`;

export const Post = styled.article`
  position: relative;
  margin-bottom: 80px;
  padding-bottom: 81px;

  &:after {
    background: #a8a8a8;
    bottom: 0;
    content: "";
    height: 1px;
    left: 20%;
    position: absolute;
    width: 60%;
  }

  p:last-child {
    margin-bottom: 0;
  }
`;

export const Tags = styled.div`
  margin-top: 4px;
`;

export const Tag = styled.span`
  background: ${({ highlight }) => (highlight ? "#5e5e5e" : "#a8a8a8")};
  border-radius: 9px;
  color: white;
  display: inline-block;
  font-size: 14px;
  line-height: 22px;
  margin-right: 6px;
  padding: 0 6px;
`;

export const Title = styled.h2`
  font-size: 34px;
  font-weight: 400;
  line-height: 42px;
  margin: 0 0 8px 0;
  padding: 0;
`;

export const TitleLink = styled(Link)`
  text-decoration: none;
`;
