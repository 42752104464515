import styled from "styled-components";

export const AuthResponse = styled.span`
  display: inline-block;
  font-weight: 600;
  margin-top: 16px;
`;

export const AuthForm = styled.form`
  text-align: center;
`;

export const Input = styled.input`
  background: none;
  border: 2px solid #a8a8a8;
  color: #5e5e5e;
  display: block;
  font-size: 24px;
  line-height: 32px;
  margin: 0 auto;
  max-width: 100%;
  outline: none;
  text-align: center;
`;

export const LogOut = styled.button`
  background: none;
  border: none;
  box-shadow: none;
  color: #3d3d3d;
  cursor: pointer;
  display: block;
  margin: 0 0 0 auto;
  padding: 0;
  outline: none;
`;
