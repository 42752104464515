import styled from "styled-components";

export const Header = styled.header`
  width: 100%;
`;

export const InnerHeader = styled.div`
  margin: 0 auto;
  max-width: 840px;
  width: 100%;
`;

export const Logo = styled.img`
  display: block;
  margin: 60px auto;
`;
